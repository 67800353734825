import { Component } from '@angular/core';
import { monthOptions } from '../utils/helpers';
import { PaymentSlipResponseDTO } from '../interfaces/payment-slips.interface';
import { CompanyService } from '../services/company.service';
import { IComboBase } from '../interfaces/base/base.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SolicitudeService } from './services/solicitude.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLoadingComponent } from '../shared/modals/modal-loading/modal-loading.component';
import { ModalSuccessComponent } from '../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../shared/modals/modal-reject/modal-reject.component';

@Component({
  selector: 'app-solicitude',
  templateUrl: './solicitude.component.html',
  styleUrls: ['./solicitude.component.scss'],
})
export class SolicitudeComponent {
  dataSource: PaymentSlipResponseDTO[] = [];
  mostrar: boolean = false;
  dataToEdit: any = null;
  mostrarModalEditar: boolean = false;
  mostrarModalDetalle: boolean = false;

  viewVacation: boolean = false;
  viewDeleted: boolean = false;
  solicitudeId: number = 0;
  typeSolicitudeId: number = 0;
  dataDetail: any = null;

  // Opciones del combo
  monthOptions: IComboBase[] = [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;
  userData: any = null;

  headerClasses: { [key: string]: string } = {
    '1': 'header-keola',
    '2': 'header-inresort',
    '3': 'header-inclub',
    '4': 'header-ribera',
    '5': 'header-keolafood',
    '6': 'header-valle',
  };

  currentHeaderClass: string = 'header-keola';

  selectedDocument: SafeResourceUrl | null = null;
  constructor(
    private solicitudeService: SolicitudeService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer
  ) {
    this.currentMonth = this.getCurrentMonth();
    this.selectedMonth = this.currentMonth;
  }

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  displayedColumns = [
    { key: 'typeSolicitudeName', header: 'Tipo de solicitud' },
    { key: 'persona', header: 'Persona' },
    { key: 'employeeDocumentNumber', header: 'D.N.I.' },
    { key: 'solicitedDays', header: 'Total días' },
    { key: 'estado', header: 'Estado' },
    { key: 'ultimaActualizacion', header: 'Última actualización' },
    
  ];

  actionButtons = [
    {
      icon: (row: any) => row.estadoId === 3 || row.estadoId === 4 ? 'pi pi-eye' : 'pi pi-check-square',
      color: '#ED672A',
      tooltip: (row: any) => row.estadoId === 3 || row.estadoId === 4 ? 'Detalle' : 'Validar',
      action: (row: any) => {
        this.dataDetail = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        switch (row.typeSolicitudeId) {
          case 1:
            break;
          case 2:
            this.viewVacation = true;
            break;
          case 3:
            // this.viewLoan = true;
            break;

          // Agrega más casos según sea necesario
          case 4:
            // Lógica para otro caso
            break;
          default:
            console.warn('Caso no manejado:', row.tipoSolicitudId);
            break;
        }
      },
      visible: (row: any) => true,
    },
    {
      icon: 'pi pi-trash',
      color: '#ED672A',
      tooltip: 'Eliminar',
      action: (row: any) => {
        this.solicitudeId = row.solicitudId;
        this.typeSolicitudeId = row.typeSolicitudeId;
        this.viewDeleted = true;
      },
      visible: (row: any) => row.estadoId !== 3,
    },
  ];

  getIconForEstado(row: any): string {
    return row.estadoId === 3 || row.estadoId === 4
      ? 'pi pi-eye'
      : 'pi pi-check-square';
  }
  // Método para asignar el ícono dinámicamente
  updateActionButtonsIcon(row: any) {
    this.actionButtons[0].icon = this.getIconForEstado(row);
  }

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
        this.selectedTabKey = this.tabs[0].key;
        this.onTabSelected(this.selectedTabKey);
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onDialogClose() {
    this.mostrar = false; // Cierra el modal
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.dataDetail = null;
    this.viewVacation = false;
    this.viewDeleted = false;
    this.solicitudeId = 0;
    this.typeSolicitudeId = 0;
  }

  onButtonConfirmedClick() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    this.solicitudeService
      .deleteSolicitude(this.solicitudeId, this.typeSolicitudeId)
      .subscribe({
        next: (response: any) => {
          loadingRef.close();
          const successMessage = response.message;
          const ref = this.dialogService.open(ModalSuccessComponent, {
            closable: true,
            data: {
              text: successMessage,
              title: '¡Solicitud eliminada!',
              icon: 'pi pi-check-circle',
              showButton: true,
              buttonText: 'Actualizar tabla',
              buttonAction: () => {
                this.filterData();
              },
            },
          });
        },
        error: (err) => {
          console.error('Error al eliminar la solicitud:', err);
          const ref = this.dialogService.open(ModalRejectComponent, {
            closable: true,
            data: {
              text:
                err.error?.error ||
                'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
              title: '¡Lo sentimos!',
              showButton: true,
            },
          });
        },
      });
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData(); // Aplicar filtro cuando cambia el mes
  }

  private getCurrentMonth(): IComboBase | null {
    const currentMonthKey = new Date().toLocaleDateString('en-GB', {
      month: '2-digit',
    });
    return (
      this.monthOptions.find((option) => option.key === currentMonthKey) || null
    );
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData(); // Aplicar filtro cuando cambia la búsqueda
  }

  filterData(): void {
    this.isLoading = true;
    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key == '00' ? null : this.selectedMonth?.key, // Si no hay mes seleccionado, enviar null
      companyId: this.selectedTabKey || null,
    };

    this.solicitudeService.postSolicitudesByCompany(filters).subscribe({
      next: (data: PaymentSlipResponseDTO[]) => {
        this.dataSource = data;
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    this.currentHeaderClass = this.headerClasses[tabKey] || 'header-keola';
    this.selectedDocument = null;
    this.filterData();
  }

  onRowSelected(row: any): void {
    if (row && row.paymentSlipUrl) {
      const uniqueUrl = `${
        row.paymentSlipUrl
      }?timestamp=${new Date().getTime()}`;
      this.selectedDocument =
        this.sanitizer.bypassSecurityTrustResourceUrl(uniqueUrl);
    } else {
      this.selectedDocument = null;
    }
  }

  onUpdateStateComplete(): void {
    this.filterData();
  }

  onEditComplete(): void {
    this.filterData();
  }

  onDeleteComplete(): void {
    this.filterData();
  }
}
