<p-dialog
  header="Vacaciones"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '30%', height: '60vh' }
  "
  class="update-state-modal"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="content-container" *ngIf="!isLoading">
    <!-- Título del préstamo -->
    <!-- <div class="flex justify-content-between align-items-center">
      <h2 class="text-orange-600"></h2>
    </div> -->

    <!-- Estado de la solicitud -->
    <div class="progress-bar">
      <div class="line-background"></div>
      <div
        class="line-active"
        [style.width.%]="
          status === 'aprobado' || status === 'rechazado'
            ? ((currentStep - 1) / (steps.length - 1)) * 99
            : (currentStep / (steps.length - 1)) * 99
        "
        [ngClass]="{
          'line-accepted': status === 'aprobado',
          'line-rejected': status === 'rechazado'
        }"
      ></div>

      <div *ngFor="let item of visibleSteps; let i = index" class="step">
        <div class="circle-container">
          <div
            class="circle"
            [ngClass]="{
              active:
                i < currentStep ||
                (status === 'rechazado' && i < currentStep - 1),
              accepted: status === 'aprobado',
              rejected: i === currentStep - 1 && status === 'rechazado'
            }"
          ></div>
        </div>

        <div class="label">
          {{
            status === "aprobado" && i === 3
              ? ""
              : i === 2 && status === "rechazado"
              ? "Rechazado"
              : item.label
          }}
        </div>
      </div>
    </div>

    <!-- Detalles de la solicitud -->
    <div class=" p-field mt-4">
      <p class="text-orange-400">Nombre del colaborador</p>
      <p>
        {{ collaborator | capitalize }}
      </p>

      <div class="flex flex-column gap-5">
        <div>
          <p class="text-orange-400">Inicio de vacaciones</p>
          <p>{{ dateStart }}</p>
        </div>

        <div>
          <p class="text-orange-400">Fin de vacaciones</p>
          <p>{{ dateEnd }}</p>
        </div>
      </div>

      <p class="text-orange-400">Mensaje</p>
      <p>
        {{
          message == null ? "No cuenta con descripción de solicitud." : message
        }}
      </p>
    </div>

    <!-- Mensaje de administración -->
    <div class="p-field mt-4">
      <label for="adminMessage" class="text-orange-400"
        >Mensaje de administración</label
      >
      <div
        class="textarea-container"
        *ngIf="showTextarea; else adminMessageText"
      >
        <textarea
          id="adminMessage"
          rows="5"
          [(ngModel)]="adminMessage"
          placeholder="Escriba su mensaje aquí..."
          maxlength="200"
        ></textarea>
        <small>200/{{ adminMessage.length || 0 }}</small>
      </div>
      <ng-template #adminMessageText>
        <p>{{ adminMessage ? adminMessage : 'No se cuenta con mensaje de administración.' }}</p>
      </ng-template>
    </div>

    <!-- Confirmación -->
    <div class="confirmation" *ngIf="showConfirmation">
      <p-checkbox
        [(ngModel)]="confirmationChecked"
        binary="true"
        inputId="confirmation"
      />
      <label for="confirmation">
        He revisado detalladamente la solicitud
      </label>
    </div>

    <!-- Botones de aprobación / rechazo -->
    <div class="submit-button" *ngIf="showButtons">
      <button
        pButton
        type="button"
        [label]="primaryButtonLabel"
        class="approve-button"
        (click)="onPrimaryButtonClick()"
        [disabled]="validateForm()"
      ></button>
      <button
        pButton
        type="button"
        label="RECHAZAR"
        class="reject-button"
        (click)="onRejectClick()"
        [disabled]="validateForm()"
      ></button>
    </div>
  </div>
</p-dialog>
